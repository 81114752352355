/* ConstructionMessage.css */

.construction-message {
    position: fixed;
    padding: 10px;
    border-radius: 5px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
    background-color: rgb(230, 0, 0, 0.7); /* Change the background color to red */
    color: white; /* Change the text color to white for better visibility */
    transition: left 1s ease, top 1s ease; /* Add a smooth transition effect for position changes */
  }
  