/* Tmp.css */
/* Use "Jura" font for the heading and text */
.tmp-heading {
    font-family: 'Jura', sans-serif;
    font-weight: bold;
    font-size: 3rem;
  }
  
  .tmp-text {
    font-family: 'Jura', sans-serif;
    font-size: 1rem;
    margin-top: 5%;
    width: 80%;
  }
  
  .tmp-link {
    color: #BCDED7;
    text-decoration: underline;
  }
  