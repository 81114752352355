/* mainContentStyles.css */
@import url('https://fonts.googleapis.com/css2?family=Jura');
/* Style the main content section */
main {
  font-family: 'Jura', sans-serif;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 30px;
}

/* Style the green plane */
.green-panel {
  background-color:#BCDED7;
  opacity: 1;
  height: 100%;
  padding: 2%;
  border-radius: 8px;
  font-family: 'Jura', sans-serif;
}

/* Format the text within the green plane */
.green-panel h1 {
  font-size: 500%;
  font-weight: bold;
  color: rgb(0, 0, 0);
  text-align: center;
  margin-top: 0;
  margin-bottom: 5%;
}

.green-panel p {
  font-size: 100%;
  color: rgb(0, 0, 0);
  text-align: center;
  margin: 0; /* Remove default margin */
}

/* Style the photo section */
.photo img {
  width: 35%;
  height: 35%;
  border-radius: 50%;
  margin-top: 5%;
  margin-bottom: 5%;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
}

/* Quotation.css */
.quote {
  font-family: 'Jura', sans-serif;
  font-size: 18px;
  font-weight: bold;
  margin-bottom: 1%;
}

.author {
  font-family: 'Jura', sans-serif;
  font-size: 12px;
  font-weight: semi-bold;
  margin-bottom: 0px;
}

/* Remove the background and padding */
.quotation {
  font-family: 'Jura', sans-serif;
  background-color: transparent;
  margin-bottom: 10%;
}

.center-content {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100%;
}
