/* Header.css */

@import url('https://fonts.googleapis.com/css?family=Jura');

.navbar {
  display: flex;
  justify-content: center;
  background-color: transparent;
  margin-top: 1%;
  margin-bottom: 1%;
}

/* Style the navigation items (buttons) using flexbox */
.navbar ul.navbar-nav {
  display: flex;
  align-items: center;
  justify-content: center; /* Center the buttons along the main axis */
  list-style: none;
  padding: 0;
  margin: 0;
  gap: 15%; /* Use a percentage-based gap between buttons */
}

.navbar ul.navbar-nav li {
  display: flex;
  align-items: center;
  margin: 0;
  padding: 0;
}

.navbar ul.navbar-nav li .btn {
  font-family: 'Jura', sans-serif;
  margin: 0;
  border: none;
  padding: 1% 5%; /* Reduced padding to make the button narrower */
  transition: transform 0.1s ease; /* Added transition for smooth zoom effect */
  transform-origin: center; /* Set the transform origin to the center */
  display: flex; /* Use flexbox for centering the text within the button */
  justify-content: center; /* Horizontally center the text within the button */
  align-items: center; /* Vertically center the text within the button */
}

.navbar ul.navbar-nav li .custom_btn {
  background-color: transparent;
}

.navbar ul.navbar-nav li .custom_btn:hover {
  transform: scale(1.2); /* Zoom in the button on hover */
  background-color: #BCDED7;
  color: white;
}

.navbar ul.navbar-nav li .custom_btn:active {
  background-color: #70A390;
  transform: scale(0.8); /* Zoom out the button on active */
  color: white;
}
